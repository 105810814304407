.container-large {
  max-width: 1184px !important;
}

.padding-top-bottom {
  padding: 164px 0;
}

/* text */

.landing-tracks-text-primary-large {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.landing-tracks-text-primary-x-large {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.04em;
}

.landing-tracks-text-primary-xx-large {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.landing-tracks-text-primary-x-small {
  color: #fff;
  text-transform: uppercase;
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.landing-tracks-text-primary-xx-small {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.landing-tracks-text-primary-small {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.landing-tracks-text-second-small {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #becff1;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.landing-tracks-text-second-x-small {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #becff1;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.landing-tracks-text-second-medium {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #becff1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.message-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 46px;
  min-width: 105px;
  padding: 8px 12px;
  margin: 10px 0;
  border-radius: 4px;
}

.message-text {
  font-size: 12px;
}

/* text */

/* .main-wrapper .page-video-track .section-info-track {
  padding-left: 128px;
  display: flex;
  flex-direction: column;
  max-width: 781px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-bottom: 118px;
} */

.page-video-track .w-nav-link {
  color: #fff !important;
}

.main-wrapper.page-video-track {
  background-size: cover !important;
}

.main-wrapper .page-video-track .section-info-track .users-counter {
  display: flex;
  width: 194px;
  height: 32px;
  margin-bottom: 16px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .users-counter
  .user-avatar {
  display: inline;
  width: 32px;
  height: 32px;
  margin-left: -7px;
  border-radius: 32px;
}
.main-wrapper
  .page-video-track
  .section-info-track
  .users-counter
  .user-avatar:first-child {
  margin-left: 0px;
}

.main-wrapper .page-video-track .section-info-track .users-counter .counter {
  padding: 8px 16px;
  margin-left: -7px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 32px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-info-track
  .info-track-title {
  margin-bottom: 16px;
  max-width: 780px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-info-track
  .info-track-text {
  margin-bottom: 16px;
  max-width: 500px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-info-track
  .author {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-info-track
  .author
  .author-avatar {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-buttons
  .button-iniciar {
  padding: 13px 24px;
  text-transform: uppercase;
  color: #126c46;
  border-radius: 4px;
  background: #3de8a0;
  margin-right: 8px;
}

.main-wrapper
  .page-video-track
  .section-info-track
  .container-buttons
  .button-trailer {
  padding: 13px 24px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background-color: transparent;
}

.main-wrapper .section-buy-access {
  background-color: #1e1e2f;
}

.main-wrapper .section-buy-access .container-buy-access {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  align-content: center;
}

.main-wrapper .section-buy-access .container-buy-access button {
  padding: 13px 24px;
  text-transform: uppercase;
  color: #126c46;
  border-radius: 4px;
  background: #3de8a0;
}

.main-wrapper .section-buy-access .track-max-width {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 220px;
  align-items: center;
}

.main-wrapper .section-buy-access .track-card-mobile {
  border-width: 0px 1px;
  border-style: solid;
  border-color: #303355;
  border-radius: 4px;
}

/* .wrapper-buy-access .container .card {
  padding: 24px;
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.wrapper-buy-access .container .card p {
  margin-bottom: 0;
}

.wrapper-buy-access .container .card button{
  padding: 13px 24px;
  text-transform: uppercase;
  color: #126C46;
  border-radius: 4px;
  background: #3DE8A0;
} */

.main-wrapper .section-collectible-badge {
  background-color: #27293d;
}

.main-wrapper .section-collectible-badge .container-collectible-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  letter-spacing: -0.01em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-badge
  .container-background-icon {
  position: absolute;
  width: 108px;
  height: 108px;
  background: #1e1e2f;
  border: 2px solid #3de8a0;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
  border-radius: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -10px;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-badge
  .icon-trophy {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -15px;
  width: 32px;
  height: 32px;
  background: #3de8a0;
  border-radius: 48px;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-badge
  .icon-badge {
  border-radius: 96px;
  width: 96px;
  height: 96px;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-name-badge {
  width: 300px;
  height: 93px;
  background: linear-gradient(180deg, #6cffc1 0%, #22e493 100%);
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-name-badge
  .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #126c46;
  margin-bottom: 8px;
  margin-left: 64px;
}

.main-wrapper
  .section-collectible-badge
  .container-collectible-badge
  .container-name-badge
  .classification {
  margin-left: 100px;
  margin-right: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #0a4d30;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-wrapper .section-modules {
  background-color: #1e1e2f;
}

.main-wrapper .section-modules .page-padding-tracks {
  padding-left: 1rem;
  padding-right: 0;
  padding-bottom: 96px;
}

.main-wrapper .section-modules .container-padding-top {
  padding-top: 64px !important;
}

.main-wrapper .section-modules .container-large {
  max-width: 1205px !important;
}

.main-wrapper .section-modules .padding-top-bottom {
  padding-top: 0px;
  padding-bottom: 96px;
}

.main-wrapper .section-modules .module-name {
  text-align: start;
  padding-bottom: 24px;
  letter-spacing: -0.01em;
  font-size: 18px;
  font-weight: 800;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.main-wrapper .section-modules .modules-button {
  padding: 13px 22px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  margin: 0 auto;
  display: block;
}

/* .main-wrapper .section-modules .slick-slide {
  width: 302px !important;
  display: flex;
  justify-content: space-between;
} */

.main-wrapper .section-modules .container-card {
  position: relative;
  width: 100% !important;
  padding: 0;
  margin: 0;
}

.main-wrapper .section-modules .container-card .text-padding-top {
  font-size: 14px;
  padding-top: 16px;
  font-weight: 400;
}

.main-wrapper .section-modules .container-card .emojis-thumbnail {
  display: flex;
  position: absolute;
  top: 10px;
  right: 34px;
  font-size: 20px;
  padding: 4px 4px 4px 14px;
  z-index: 1;
  background: linear-gradient(#1e1e30, rgba(30, 30, 48, 0.9254901961));
  border-radius: 5px;
}

.main-wrapper .section-modules .container-card .emojis-thumbnail-none {
  display: none;
}

.main-wrapper .section-modules .container-card .emojis-thumbnail .emoji-thumbnail {
  margin-left: -10px;
}

.main-wrapper .section-modules .container-card .container-lock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 10px;
  width: 24px;
  height: 24px;
  background: #3de8a0;
  border-radius: 14px;
}

.main-wrapper .section-modules .slide-items .slick-next {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  right: 0;
  width: 48px;
  height: 64px;
  background: #3de8a0;
  z-index: 99;
}

.main-wrapper .section-modules .slide-items .slick-next:hover {
  background: #3de8a0;
}

.main-wrapper .section-modules .slide-items .slick-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  left: 0;
  width: 48px;
  height: 64px;
  background: #3de8a0;
  z-index: 99;
}

.main-wrapper .section-modules .slide-items .slick-prev:hover {
  background: #3de8a0;
}

.main-wrapper .section-modules .slide-items .slick-list {
  padding: 0 !important;
}

.main-wrapper .section-modules .slide-items .slick-list .slick-track {
  margin-left: 0 !important;
}

.slide-items .slick-slide .container-card {
  padding-right: 24px;
}

/* .slide-items .slick-slide .container-card:first-child {
  padding: 0 !important;
}

.slide-items .slick-slide .container-card:last-child {
  width: 278px !important;
} */

.slide-items .slick-slide .container-card .image-module-video {
  width: 100%;
  height: auto; /*182px*/
  object-fit: cover; /*fill*/
  /* background-color: rgba(0, 0, 0, 0.34); */
}

.slide-items .slick-prev.slick-disabled,
.slide-items .slick-next.slick-disabled {
  display: none !important;
}

.slide-items .slick-prev::before,
.slide-items .slick-next::before {
  content: none;
}

.slide-items .slick-dots {
  display: none !important;
}

.main-wrapper .section-tracks {
  background-color: #27293d;
}

.main-wrapper .section-tracks .page-padding-right-tracks {
  padding-right: 1rem;
}

.main-wrapper .section-tracks .page-padding-left-tracks {
  padding-left: 1rem;
}

.main-wrapper .section-tracks .paradigma-tracks {
  display: flex;
  /* justify-content: center; */
  overflow-y: hidden;
  overflow-x: auto;
}

.main-wrapper .section-tracks .paradigma-tracks::-webkit-scrollbar {
  display: none;
}

.main-wrapper .section-tracks .container-track {
  display: flex;
  justify-content: center;
}

.main-wrapper .section-tracks .card-track {
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  margin-right: 24px;
  min-width: 278px;
  max-width: 278px;
  height: 440px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.48);
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.24));
}

.main-wrapper .section-tracks .card-track .track-image-gradient {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.main-wrapper .section-tracks .card-track .track-container-text {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  padding: 24px;
  bottom: 0;
}

.main-wrapper .section-tracks button {
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  padding: 13px 76px;
  margin-top: 6px;
  background-color: transparent;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0 0, 0, 0.16);
}

.card-filter-blur {
  filter: blur(10px);
}

.text-filter-blur {
  filter: blur(7px);
}

.main-wrapper .section-tracks .card-track .soon-text {
  position: absolute;
  z-index: 12;
  text-align: center;
  bottom: 0;
  padding-bottom: 64px;
  width: 100%;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.main-wrapper .section-video-trailer {
  background-color: #1e1e2f;
}

.main-grid.paradigma-video-trailer {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
}

.main-wrapper .section-video-trailer .card-container {
  padding-bottom: 164px;
  display: flex;
  justify-content: center;
}

.main-wrapper .section-video-trailer .card {
  background-color: #27293d;
  /* padding: 0 24px; 
  margin-right: 24px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  text-align: center;
}

.main-wrapper .section-video-trailer .card .card-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 8px;
}

.main-wrapper .section-video-trailer .card .card-text-size {
  padding: 0 24px 64px 24px;
  max-width: 330px;
}

.main-wrapper .section-video-trailer .video-container .title-container {
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  padding: 164px 0 64px 0;
}

.main-wrapper .section-video-trailer .video-container .video-trailer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* .main-wrapper .section-video-trailer .video-container .video-trailer::after {
  padding-top: 56.25%;
  display: block;
  content: '';
} */

.main-wrapper .section-video-trailer .video-container .video-trailer video {
  width: 100%;
  height: auto;
}

.main-wrapper
  .section-video-trailer
  .video-container
  .video-trailer
  .icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-discord {
  background-color: #27293d;
}

.section-discord .discord-title {
  text-transform: none;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 16px;
  letter-spacing: -0.04em;
}

.section-discord .discord-text {
  max-width: 781px;
  text-transform: none;
  text-align: center;
  margin: 0 auto;
}

.main-wrapper .section-feedbacks {
  background-color: #1e1e2f;
}

.main-wrapper .section-feedbacks .padding-top-bottom {
  padding: 164px 0 96px 0;
}

.main-wrapper .section-feedbacks .feedbacks-title {
  width: 100%;
  max-width: 835px;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 0 auto;
}

.main-wrapper .section-sign-paradigma {
  background-color: #27293d;
}

.main-wrapper .section-sign-paradigma .title-vire-pro {
  max-width: 210px;
  text-align: end;
}

.main-wrapper .section-sign-paradigma .paradigma-section-right {
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #303355;
  align-items: flex-start;
  justify-content: center;
}

.main-wrapper
  .section-sign-paradigma
  .paradigma-section-right
  .right-title-small {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #becff1;
}

.main-wrapper
  .section-sign-paradigma
  .paradigma-section-right
  .right-title-large {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.main-wrapper
  .section-sign-paradigma
  .paradigma-section-right
  .right-title-medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.main-wrapper .section-sign-paradigma .paradigma-section-right button {
  margin-top: 24px;
  padding: 13px 24px;
  text-transform: uppercase;
  color: #126c46;
  border-radius: 4px;
  background: #3de8a0;
}

.main-wrapper .section-sign-paradigma .main-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-grid.paradigma-sign {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  grid-template-columns: 1fr 1fr;
}

.section-satisfaction {
  background-color: #1e1e2f;
}

.main-wrapper .section-satisfaction .main-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.main-wrapper
  .section-satisfaction
  .main-grid.paradigma-satisfaction
  .satisfaction-badge-money {
  width: 200px;
  height: 200px;
  justify-self: end;
  margin-right: 63px;
}

.main-grid.paradigma-satisfaction {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 40% 1fr;
}

.landing-page-tracks .section_footer {
  background-color: #27293d !important;
}

.icon-whatsapp {
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 10px;
}

.icon-whatsapp a img {
  width: 70px;
  height: 70px;
}

.padding-bottom-step {
  padding-bottom: 10px;
}

.step-left {
  padding: 16px;
  width: 70%;
  text-align: right;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #becff1;
}

.step-right {
  padding: 16px;
  position: relative;
  left: -1px;
  text-align: left;
  width: 70%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #becff1;
}

/* @media screen and (min-width: 1440px) {
  .slide-items .slick-slide .container-card .image-module-video {
    height: 182px;
  }
}

@media screen and (max-width: 1024px) {
  .slide-items .slick-slide .container-card .image-module-video {
    height: 149px;
  }
} */

@media screen and (max-width: 991px) {
  .landing-tracks-text-primary-xx-large {
    font-size: 32px;
    line-height: 38px;
  }

  .main-wrapper .section-buy-access .padding-xlarge {
    padding: 0;
  }

  .main-wrapper .section-buy-access .margin-xlarge {
    margin: 0;
  }

  .main-wrapper .section-buy-access .container-buy-access {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    align-content: center;
  }

  .main-wrapper .section-buy-access .track-card-mobile {
    border-width: 1px 0px;
  }

  .main-wrapper .section-tracks .padding-top-bottom {
    padding: 64px 0;
  }

  .main-wrapper .section-video-trailer .video-container .title-container {
    padding: 96px 0 32px 0;
    font-size: 24px;
    line-height: 29px;
  }

  .main-wrapper .section-video-trailer .padding-top-bottom {
    padding-top: 48px;
    padding-bottom: 96px;
  }

  .main-wrapper .section-discord .discord-title {
    font-size: 24px;
    line-height: 29px;
  }

  .main-wrapper .section-feedbacks .padding-top-bottom {
    padding: 96px 0 32px 0;
  }

  .main-wrapper .section-sign-paradigma .main-grid {
    flex-direction: column;
  }

  .main-wrapper .section-sign-paradigma .paradigma-section-right {
    text-align: center;
    align-items: stretch;
    border: none;
  }

  .main-wrapper .section-sign-paradigma .title-vire-pro {
    text-align: center;
  }

  .main-wrapper .section-satisfaction .main-grid.paradigma-satisfaction {
    grid-template-columns: 1fr;
  }

  .main-wrapper
    .section-satisfaction
    .main-grid.paradigma-satisfaction
    .satisfaction-badge-money {
    margin: 0 auto;
    width: 128px;
    height: 128px;
  }

  .main-wrapper
    .section-satisfaction
    .main-grid.paradigma-satisfaction
    .container-text-mobile {
    text-align: center;
    padding-top: 24px;
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  /* .slide-items .slick-slide .container-card .image-module-video {
    height: 390px;
  } */

  .page-video-track .w-nav-link {
    color: #222222 !important;
  }

  .step-right, 
  .step-left {
    width: 100%;
  }
  
}

/* @media screen and (max-width: 425px) {
  .slide-items .slick-slide .container-card .image-module-video {
    height: 205px;
  }
}

@media screen and (max-width: 375px) {
  .slide-items .slick-slide .container-card .image-module-video {
    height: 180px;
  }
}

@media screen and (max-width: 320px) {
  .slide-items .slick-slide .container-card .image-module-video {
    height: 150px;
  }
} */
/* font-size: 24px;
line-height: 29px; */
